import { Box, Divider, Flex, Heading, Text } from '@mediahuis/chameleon-react';
import { FeaturesList } from '@mediahuis/subscriber-ui';

import DefaultVitrineCardBase from './DefaultVitrineCardBase';

const DefaultVitrineCardLux = ({
  buttonCaption,
  buttonText,
  description,
  features,
  highlight,
  highlightColor,
  imageSrc,
  label,
  logoSrc,
  priceCaption,
  priceText,
  testId,
  title,
  onClick,
}) => {
  return (
    <DefaultVitrineCardBase
      buttonCaption={buttonCaption}
      buttonText={buttonText}
      gap="1.5rem"
      highlight={highlight}
      highlightColor={highlightColor}
      label={label}
      testId={testId}
      title={title}
      onClick={onClick}
    >
      {logoSrc ? (
        <Box alignSelf="center" height="28px" position="relative" width="85%">
          <img
            alt={title?.props?.source}
            height="100%"
            src={logoSrc}
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            width="100%"
          />
        </Box>
      ) : (
        <Heading
          alignSelf="center"
          fontFamily="primary"
          level={3}
          style={{ width: '85%' }}
          textAlign="center"
          textTransform="capitalize"
        >
          {title}
        </Heading>
      )}

      {description && (
        <Text
          alignSelf="center"
          as="p"
          fontFamily="system"
          style={{ width: '85%' }}
          textAlign="center"
        >
          {description}
        </Text>
      )}

      {imageSrc && (
        <Box alignSelf="center" height="100px" position="relative" width="85%">
          <img
            alt={title?.props?.source}
            height="100%"
            src={imageSrc}
            style={{
              objectFit: 'contain',
            }}
            width="100%"
          />
        </Box>
      )}

      <Divider style={{ alignSelf: 'center', width: '85%' }} />

      <Box
        alignSelf="center"
        flexGrow={1}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: '85%',
        }}
      >
        <FeaturesList availableOnly gap="1rem" items={features} />
      </Box>

      <Divider style={{ alignSelf: 'center', width: '85%' }} />

      <Flex
        alignSelf="center"
        flexDirection="column"
        style={{ gap: '0.5rem', width: '85%' }}
      >
        {priceCaption && (
          <Text as="p" fontFamily="system" textAlign="center">
            {priceCaption}
          </Text>
        )}

        <Text as="p" fontFamily="system" textAlign="center">
          {priceText}
        </Text>
      </Flex>
    </DefaultVitrineCardBase>
  );
};

export default DefaultVitrineCardLux;
